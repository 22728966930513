import React from 'react'
import { IndexRoute, Redirect, Route } from 'react-router'

import { App } from 'client/bookmate/app'
import HeaderBox from 'client/shared/boxes/header-box'
import { Loader } from 'client/shared/blocks/loader'
import loadable from '@loadable/component'
import { THREE_MES } from 'client/bookmate/pages/telcel-page'
import {
  EL_TEATRO,
  MARIPOSA,
  VIDADES,
} from 'client/bookmate/pages/originals/el-ano-de-mariposa'

const fallback = <HeaderBox />
const resourceFallback = <Loader />
const NullComponent = null

const KeriCinPage = loadable(
  () => import('client/bookmate/pages/originals/keri-cin'),
  {
    fallback: NullComponent,
  },
)

const UserPage = loadable(() => import('client/bookmate/pages/user-page'), {
  fallback,
})
const UserIndexBox = loadable(
  () => import('client/bookmate/boxes/user-index-box'),
  {
    fallback,
  },
)
const UserBooksBox = loadable(
  () => import('client/bookmate/boxes/user-books-box'),
  {
    fallback: NullComponent,
  },
)
const UserShelvesBox = loadable(
  () => import('client/bookmate/boxes/user-shelves-box'),
  {
    fallback: NullComponent,
  },
)
const UserSeriesBox = loadable(
  () => import('client/bookmate/boxes/user-series-box'),
  {
    fallback: NullComponent,
  },
)
const UserConnectionsBox = loadable(
  () => import('client/bookmate/boxes/user-connections-box'),
  {
    fallback: NullComponent,
  },
)
const UserQuotesBox = loadable(
  () => import('client/bookmate/boxes/user-quotes-box'),
  {
    fallback: NullComponent,
  },
)
const UserBookQuotesBox = loadable(
  () => import('client/bookmate/boxes/user-book-quotes-box'),
  {
    fallback: NullComponent,
  },
)
const UserImpressionsBox = loadable(
  () => import('client/bookmate/boxes/user-impressions-box'),
  {
    fallback: NullComponent,
  },
)

const UserProfileBox = loadable(
  () => import('client/bookmate/boxes/user-profile-box'),
  {
    fallback: NullComponent,
  },
)
const UserChangeCardBox = loadable(
  () => import('client/bookmate/boxes/user-change-card-box'),
  {
    fallback: NullComponent,
  },
)
const UserSettingsBox = loadable(
  () => import('client/bookmate/boxes/user-settings-box'),
  {
    fallback,
  },
)
const DevicesShortBox = loadable(
  () => import('client/bookmate/boxes/user-devices-short-box'),
  {
    fallback,
  },
)
const UserChangePasswordBox = loadable(
  () => import('client/bookmate/boxes/user-change-password-box'),
  {
    fallback: NullComponent,
  },
)
const UserChangeEmailBox = loadable(
  () => import('client/bookmate/boxes/user-change-email-box'),
  {
    fallback: NullComponent,
  },
)
const UserAddEmailBox = loadable(
  () => import('client/bookmate/boxes/user-add-email-box'),
  {
    fallback: NullComponent,
  },
)
const UserAddEmailSuccessBox = loadable(
  () => import('client/bookmate/boxes/user-add-email-success-box'),
  {
    fallback: NullComponent,
  },
)
const UserDeleteAccountPasswordBox = loadable(
  () => import('client/bookmate/boxes/user-delete-account-password-box'),
  {
    fallback: NullComponent,
  },
)
const UserPrivateBox = loadable(
  () => import('client/bookmate/boxes/user-private-box'),
  {
    fallback: NullComponent,
  },
)

const UserFamilySubscriptionBox = loadable(
  () => import('client/bookmate/blocks/family-subscription'),
  {
    fallback,
  },
)

const FamilySubscriptionInviteSentPage = loadable(
  () =>
    import(
      'client/bookmate/blocks/family-subscription/family-subscription-invite-sent-page'
    ),
  {
    fallback,
  },
)

const UnavailablePage = loadable(
  () => import('client/bookmate/pages/unavailable-page'),
  {
    fallback,
  },
)

const IndexPage = loadable(() => import('client/bookmate/pages/index-page'), {
  fallback,
})
const SearchPage = loadable(() => import('client/bookmate/pages/search-page'), {
  fallback,
})

const AuthorPage = loadable(() => import('client/bookmate/pages/author-page'), {
  fallback,
})
const AuthorIndexBox = loadable(
  () => import('client/bookmate/boxes/author-index-box'),
  {
    fallback: NullComponent,
  },
)
const AuthorImpressionsBox = loadable(
  () => import('client/bookmate/boxes/author-impressions-box'),
  {
    fallback: NullComponent,
  },
)

const AuthorWorksBox = loadable(
  () => import('client/bookmate/boxes/author-works-box'),
  {
    fallback: NullComponent,
  },
)

const AuthorSeriesBox = loadable(
  () => import('client/bookmate/boxes/author-series-box'),
  {
    fallback: NullComponent,
  },
)

const AuthorMediaLinksBox = loadable(
  () => import('client/bookmate/boxes/author-medialinks-box'),
  {
    fallback: NullComponent,
  },
)

const AuthorQuotesBox = loadable(
  () => import('client/bookmate/boxes/author-quotes-box'),
  {
    fallback: NullComponent,
  },
)

const AuthorBooksBox = loadable(
  () => import('client/bookmate/boxes/author-books-box'),
  {
    fallback: NullComponent,
  },
)

const TopicAllBox = loadable(
  () => import('client/bookmate/boxes/topic-all-box'),
  {
    fallback: NullComponent,
  },
)

const ShowcaseTopicBox = loadable(
  () => import('client/bookmate/boxes/showcase-topic-box'),
  {
    fallback: NullComponent,
  },
)

const ShowcaseSectionBox = loadable(
  () => import('client/bookmate/boxes/showcase-section-box'),
  {
    fallback: NullComponent,
  },
)
const KidsSectionBox = loadable(
  () => import('client/bookmate/boxes/kids-section-box'),
  {
    fallback: NullComponent,
  },
)

const ShowcaseTopicSectionBox = loadable(
  () => import('client/bookmate/boxes/showcase-topic-section-box'),
  {
    fallback: NullComponent,
  },
)

const AudioBookPage = loadable(
  () => import('client/bookmate/pages/audiobook-page'),
  {
    resourceFallback,
  },
)
const AudiobookIndexBox = loadable(
  () => import('client/bookmate/boxes/audiobook-index-box'),
  {
    resourceFallback,
  },
)
const AudioBookListenersBox = loadable(
  () => import('client/bookmate/boxes/audiobook-listeners-box'),
  {
    fallback: NullComponent,
  },
)
const AudioBookRelatedBox = loadable(
  () => import('client/bookmate/boxes/audiobook-related-box'),
  {
    fallback: NullComponent,
  },
)

const ComicbookPage = loadable(
  () => import('client/bookmate/pages/comicbook-page'),
  {
    resourceFallback,
  },
)
const ComicbookIndexBox = loadable(
  () => import('client/bookmate/boxes/comicbook-index-box'),
  {
    resourceFallback,
  },
)
const ComicbookSectionBox = loadable(
  () => import('client/bookmate/boxes/comicbook-section-box'),
  {
    resourceFallback,
  },
)
const ComicbookRelatedBox = loadable(
  () => import('client/bookmate/boxes/comicbook-related-box'),
  {
    fallback: NullComponent,
  },
)
const ComicbookShelvesBox = loadable(
  () => import('client/bookmate/boxes/comicbook-shelves-box'),
  {
    fallback: NullComponent,
  },
)
const ComicbookReadersBox = loadable(
  () => import('client/bookmate/boxes/comicbook-readers-box'),
  {
    fallback: NullComponent,
  },
)

const BookPage = loadable(() => import('client/bookmate/pages/book-page'), {
  resourceFallback,
})
const ShelfPage = loadable(() => import('client/bookmate/pages/shelf-page'), {
  fallback,
})
const ShelvesPage = loadable(
  () => import('client/bookmate/pages/shelves-page'),
  {
    fallback,
  },
)
const ShelvesLayoutBox = loadable(
  () => import('client/bookmate//boxes/shelves-layout-box'),
  {
    resourceFallback,
  },
)
const SubscriptionPage = loadable(
  () => import('client/bookmate/pages/subscription-page'),
  {
    fallback,
  },
)
const ManageSubscriptionsPage = loadable(
  () => import('client/bookmate/pages/manage-subscriptions-page'),
  {
    fallback,
  },
)
const SubscriptionIndexBox = loadable(
  () => import('client/bookmate/boxes/subscription-index-box'),
  {
    fallback,
  },
)
const SubscriptionFormBox = loadable(
  () => import('client/bookmate/boxes/subscription-form-box'),
  {
    fallback: NullComponent,
  },
)
const SubscriptionUpgradeBox = loadable(
  () => import('client/bookmate/boxes/subscription-upgrade-box'),
  {
    fallback: NullComponent,
  },
)
const SubscriptionFeaturedProductBox = loadable(
  () => import('client/bookmate/boxes/subscription-featured-product-box'),
  {
    fallback: NullComponent,
  },
)
const SubscriptionCompleteBox = loadable(
  () => import('client/bookmate/boxes/subscription-complete-box'),
  {
    fallback: NullComponent,
  },
)
const SubscriptionCompletePaypalBox = loadable(
  () => import('client/bookmate/boxes/subscription-complete-paypal-box'),
  {
    fallback: NullComponent,
  },
)
const SubscriptionSuccessBox = loadable(
  () => import('client/bookmate/boxes/subscription-success-box'),
  {
    fallback: NullComponent,
  },
)
const ManageSubscriptionsBox = loadable(
  () => import('client/bookmate/boxes/manage-subscriptions-box'),
  {
    fallback: NullComponent,
  },
)

const RsOriginal = loadable(
  () => import('client/bookmate/pages/originals/nemirni-grof'),
  {
    fallback,
  },
)

const PasOriginal = loadable(
  () => import('client/bookmate/pages/originals/pas'),
  {
    fallback,
  },
)

const CovekOriginal = loadable(
  () => import('client/bookmate/pages/originals/covek'),
  {
    fallback,
  },
)

const PaolacarolaOriginal = loadable(
  () => import('client/bookmate/pages/originals/paolacarola'),
  {
    fallback,
  },
)

const WorldCoupOriginal = loadable(
  () => import('client/bookmate/pages/originals/world-coup'),
  {
    fallback,
  },
)

const FeelingsOriginal = loadable(
  () => import('client/bookmate/pages/originals/feelings'),
  {
    fallback,
  },
)

const LatamOriginal = loadable(
  () => import('client/bookmate/pages/originals/el-ano-de-mariposa'),
  {
    fallback,
  },
)

const LatamElCorazonDeLsBestia = loadable(
  () => import('client/bookmate/pages/originals/el-corazon-de-ls-bestia'),
  {
    fallback,
  },
)

const UserAchievementsBox = loadable(
  () => import('client/bookmate/boxes/user-achievements-box'),
  {
    fallback,
  },
)
const UserPledgeCardBox = loadable(
  () => import('client/bookmate/boxes/user-pledge-card-box'),
  {
    fallback: NullComponent,
  },
)
const UserFinishedBooksBox = loadable(
  () => import('client/bookmate/boxes/user-finished-books-box'),
  {
    fallback: NullComponent,
  },
)
const UserFriendsAchievementsBox = loadable(
  () => import('client/bookmate/boxes/user-friends-achievements-box'),
  {
    fallback: NullComponent,
  },
)

const BookIndexBox = loadable(
  () => import('client/bookmate/boxes/book-index-box'),
  {
    resourceFallback,
  },
)
const BookSectionBox = loadable(
  () => import('client/bookmate/boxes/book-section-box'),
  {
    resourceFallback,
  },
)
const AudioBookSectionBox = loadable(
  () => import('client/bookmate/boxes/audiobook-section-box'),
  {
    resourceFallback,
  },
)
const ShelfIndexBox = loadable(
  () => import('client/bookmate/boxes/shelf-index-box'),
  {
    fallback,
  },
)
const ShelfSectionBox = loadable(
  () => import('client/bookmate/boxes/shelf-section-box'),
  {
    fallback: NullComponent,
  },
)
const ShelfPostsBox = loadable(
  () => import('client/bookmate/boxes/shelf-posts-box'),
  {
    fallback: NullComponent,
  },
)
const ShelfBooksBox = loadable(
  () => import('client/bookmate/boxes/shelf-books-box'),
  {
    fallback: NullComponent,
  },
)
const ShelfFollowersBox = loadable(
  () => import('client/bookmate/boxes/shelf-followers-box'),
  {
    fallback: NullComponent,
  },
)
const PostFormBox = loadable(
  () => import('client/bookmate/boxes/post-form-box'),
  {
    fallback: NullComponent,
  },
)
const ShelfFormBox = loadable(
  () => import('client/bookmate/boxes/shelf-form-box'),
  {
    fallback: NullComponent,
  },
)
const ShelfResourceSearchBox = loadable(
  () => import('client/bookmate/boxes/shelf-resource-search-box'),
  {
    fallback: NullComponent,
  },
)

const BookQuotesBox = loadable(
  () => import('client/bookmate/boxes/book-quotes-box'),
  {
    fallback: NullComponent,
  },
)
const BookReadersBox = loadable(
  () => import('client/bookmate/boxes/book-readers-box'),
  {
    fallback: NullComponent,
  },
)
const BookRelatedBox = loadable(
  () => import('client/bookmate/boxes/book-related-box'),
  {
    fallback: NullComponent,
  },
)
const BookShelvesBox = loadable(
  () => import('client/bookmate/boxes/book-shelves-box'),
  {
    fallback: NullComponent,
  },
)
const BookCompletedBox = loadable(
  () => import('client/bookmate/boxes/book-completed-box'),
  {
    fallback: NullComponent,
  },
)
const ShelfSelectionBox = loadable(
  () => import('client/bookmate/boxes/shelf-selection-box'),
  {
    fallback: NullComponent,
  },
)
const ImpressionPage = loadable(
  () => import('client/bookmate/pages/impression-page'),
  {
    fallback,
  },
)
const ImpressionIndexBox = loadable(
  () => import('client/bookmate/boxes/impression-index-box'),
  {
    fallback,
  },
)
const ItemImpressionsBox = loadable(
  () => import('client/bookmate/boxes/item-impressions-box'),
  {
    fallback: NullComponent,
  },
)
const ItemImpressionFormPage = loadable(
  () => import('client/bookmate/pages/item-impression-form-page'),
  {
    fallback,
  },
)
const QuotePage = loadable(() => import('client/bookmate/pages/quote-page'), {
  fallback,
})
const QuoteIndexBox = loadable(
  () => import('client/bookmate/boxes/quote-index-box'),
  {
    fallback,
  },
)
const ReportProblemPage = loadable(
  () => import('client/bookmate/pages/report-problem-page'),
  {
    fallback,
  },
)

const ResetPasswordBox = loadable(
  () => import('client/shared/boxes/reset-password-box'),
  {
    fallback: NullComponent,
  },
)

const UploadPage = loadable(() => import('client/bookmate/pages/upload-page'), {
  fallback,
})
const NotificationsPage = loadable(
  () => import('client/shared/pages/notifications-page'),
  {
    fallback: NullComponent,
  },
)

const FeedPage = loadable(() => import('client/bookmate/pages/feed-page'), {
  fallback,
})
const AuthorsFeedBox = loadable(
  () => import('client/bookmate/boxes/authors-feed-box'),
  {
    fallback: NullComponent,
  },
)
const FriendsFeedBox = loadable(
  () => import('client/bookmate/boxes/friends-feed-box'),
  {
    fallback: NullComponent,
  },
)
const CommunityFeedBox = loadable(
  () => import('client/bookmate/boxes/community-feed-box'),
  {
    fallback: NullComponent,
  },
)

const GetTheAppPage = loadable(
  () => import('client/bookmate/pages/get-the-app-page'),
  {
    fallback,
  },
)

const DinoGame = loadable(() => import('client/bookmate/pages/dino-game'), {
  resourceFallback,
})

const YettelHungary = loadable(
  () => import('client/bookmate/pages/yettel-hungary'),
  {
    resourceFallback,
  },
)

const LoginPage = loadable(() => import('client/bookmate/pages/login-page'), {
  fallback,
})
const GoodNewsPage = loadable(
  () => import('client/bookmate/pages/good-news-page'),
  {
    fallback,
  },
)

const GiftsPage = loadable(() => import('client/bookmate/pages/gifts-page'), {
  fallback: resourceFallback,
})

const FamilySubscriptionLandingPage = loadable(
  () => import('client/bookmate/pages/family-subscription-landing-page'),
  {
    fallback: resourceFallback,
  },
)
const FamilySubscriptionLandingIndex = loadable(
  () => import('client/bookmate/blocks/family-subscription/landing'),
  {
    fallback: resourceFallback,
  },
)
const FamilySubscriptionInviteBox = loadable(
  () => import('client/bookmate/blocks/family-subscription/invite'),
  {
    fallback: resourceFallback,
  },
)

const BookSuggestionBox = loadable(
  () => import('client/bookmate/boxes/book-suggestion-box'),
  {
    fallback: NullComponent,
  },
)
const GiftsIndexBox = loadable(
  () => import('client/bookmate/boxes/gifts-index-box'),
  {
    fallback: resourceFallback,
  },
)
const GiftCustomiseBox = loadable(
  () => import('client/bookmate/boxes/gifts-customise-box'),
  {
    fallback: NullComponent,
  },
)
const GiftPaymentBox = loadable(
  () => import('client/bookmate/boxes/gift-payment-box'),
  {
    fallback: NullComponent,
  },
)
const GiftsPaymentSuccessBox = loadable(
  () => import('client/bookmate/boxes/gifts-payment-success-box'),
  {
    fallback: NullComponent,
  },
)
const GiftsPaymentFailBox = loadable(
  () => import('client/bookmate/boxes/gifts-payment-fail-box'),
  {
    fallback: NullComponent,
  },
)

const GiftPresentingPage = loadable(
  () => import('client/bookmate/pages/gift-presenting-page'),
  {
    fallback,
  },
)
const GiftPresentingBox = loadable(
  () => import('client/bookmate/boxes/gift-presenting-box'),
  {
    fallback: NullComponent,
  },
)
const GiftsSuccessBox = loadable(
  () => import('client/bookmate/boxes/gift-success-box'),
  {
    fallback: NullComponent,
  },
)

const CheapTrialPendingBox = loadable(
  () => import('client/bookmate/boxes/cheap-trial-pending-box'),
  {
    fallback: NullComponent,
  },
)

const SubscriptionPaymentPendingBox = loadable(
  () => import('client/bookmate/boxes/subscription-payment-pending-box'),
  {
    fallback: NullComponent,
  },
)

const GiftsPaymentPendingBox = loadable(
  () => import('client/bookmate/boxes/gifts-payment-pending-box'),
  {
    fallback: NullComponent,
  },
)

const PromoPaymentPendingBox = loadable(
  () => import('client/bookmate/boxes/promo-payment-pending-box'),
  {
    fallback: NullComponent,
  },
)

const CodePage = loadable(() => import('client/bookmate/pages/code-page'), {
  fallback,
})
const CodeSubscriptionBox = loadable(
  () => import('client/bookmate/boxes/code-subscription-box'),
  {
    fallback: NullComponent,
  },
)
const CodeShareBox = loadable(
  () => import('client/bookmate/boxes/code-share-box'),
  {
    fallback: NullComponent,
  },
)
const CodeSuccessBox = loadable(
  () => import('client/bookmate/boxes/code-success-box'),
  {
    fallback: NullComponent,
  },
)

const A1Page = loadable(() => import('client/bookmate/pages/a1-page'), {
  fallback,
})
const A1ShareBox = loadable(
  () => import('client/bookmate/boxes/a1-share-box'),
  {
    fallback: NullComponent,
  },
)
const A1SuccessBox = loadable(
  () => import('client/bookmate/boxes/a1-success-box'),
  {
    fallback: NullComponent,
  },
)
const CodigoPage = loadable(() => import('client/bookmate/pages/codigo-page'), {
  fallback,
})
const CodigoShareBox = loadable(
  () => import('client/bookmate/boxes/codigo-share-box'),
  {
    fallback: NullComponent,
  },
)
const CodigoSuccessBox = loadable(
  () => import('client/bookmate/boxes/codigo-success-box'),
  {
    fallback: NullComponent,
  },
)

const TelcelPage = loadable(() => import('client/bookmate/pages/telcel-page'), {
  fallback,
})
const TelcelShareBox = loadable(
  () => import('client/bookmate/boxes/telcel-share-box'),
  {
    fallback: NullComponent,
  },
)
const TelcelSuccessBox = loadable(
  () => import('client/bookmate/boxes/telcel-success-box'),
  {
    fallback: NullComponent,
  },
)

const SpecialOffersPage = loadable(
  () => import('client/bookmate/pages/special-offers-page'),
  {
    fallback,
  },
)
const SpecialOffersPaymentBox = loadable(
  () => import('client/bookmate/boxes/special-offers-payment-box'),
  {
    fallback: NullComponent,
  },
)
const SpecialOffersSuccess = loadable(
  () => import('client/bookmate/blocks/special-offer-success'),
  {
    fallback: NullComponent,
  },
)

const MTNGhana = loadable(
  () => import('client/bookmate/pages/mtn-landing-page'),
  {
    fallback: NullComponent,
  },
)

const MTNTariffs = loadable(
  () => import('client/bookmate/pages/mtn-tariffs-page'),
  {
    fallback: NullComponent,
  },
)

const MTNPayment = loadable(
  () => import('client/bookmate/pages/mtn-payment-page'),
  {
    fallback: NullComponent,
  },
)

const MTNSuccess = loadable(
  () => import('client/bookmate/pages/mtn-success-page'),
  {
    fallback: NullComponent,
  },
)

const MTN404 = loadable(() => import('client/bookmate/pages/mtn-404'), {
  fallback: NullComponent,
})

const SalinasLibrary = loadable(
  () => import('client/bookmate/pages/salinas-page'),
  {
    fallback,
  },
)

const SalinasSignUp = loadable(
  () => import('client/bookmate/boxes/salinas-sign-up-box'),
  {
    fallback: NullComponent,
  },
)

const SalinasValidate = loadable(
  () => import('client/bookmate/boxes/salinas-validate-box'),
  {
    fallback: NullComponent,
  },
)

const AboutPage = loadable(() => import('client/bookmate/pages/about-page'), {
  fallback: HeaderBox,
})
const ContactsPage = loadable(
  () => import('client/bookmate/pages/contacts-page'),
  {
    fallback: HeaderBox,
  },
)
const LegalPage = loadable(() => import('client/bookmate/pages/legal-page'), {
  fallback: HeaderBox,
})
const PressPage = loadable(() => import('client/bookmate/pages/press-page'), {
  fallback: HeaderBox,
})
const JobsPage = loadable(() => import('client/bookmate/pages/jobs-page'), {
  fallback: HeaderBox,
})
const JobPage = loadable(() => import('client/bookmate/pages/job-page'), {
  fallback: NullComponent,
})

const UnsubscribePage = loadable(
  () => import('client/bookmate/pages/unsubscribe-page'),
  {
    fallback: HeaderBox,
  },
)

const RevokeDeviceAccessTokenPage = loadable(
  () => import('client/bookmate/pages/revoke-device-access-token-page'),
  {
    resourceFallback,
  },
)

const SeriesPage = loadable(() => import('client/bookmate/pages/series-page'), {
  fallback,
})
const SeriesIndexBox = loadable(
  () => import('client/bookmate/boxes/series-index-box'),
  {
    fallback,
  },
)
const SeriesSectionBox = loadable(
  () => import('client/bookmate/boxes/series-section-box'),
  {
    fallback: NullComponent,
  },
)
const SeriesFollowersBox = loadable(
  () => import('client/bookmate/boxes/series-followers-box'),
  {
    fallback: NullComponent,
  },
)
const SeriesEpisodesBox = loadable(
  () => import('client/bookmate/boxes/series-episodes-box'),
  {
    fallback: NullComponent,
  },
)
const ItemOtherEpisodesBox = loadable(
  () => import('client/bookmate/boxes/item-other-episodes-box'),
  {
    fallback: NullComponent,
  },
)

const ResourcePublisherBox = loadable(
  () => import('client/bookmate/boxes/resource-publisher-box'),
  {
    fallback: NullComponent,
  },
)

const SitemapPage = loadable(
  () => import('client/bookmate/pages/sitemap-page'),
  {
    fallback: NullComponent,
  },
)

const ShowcasePage = loadable(
  () => import('client/bookmate/pages/showcase-page'),
  {
    fallback: NullComponent,
  },
)
const KidsShowcase = loadable(
  () => import('client/bookmate/pages/kids-showcase'),
  {
    fallback: NullComponent,
  },
)

const StatusErrorPage = loadable(
  () => import('client/errors/pages/status-error-page'),
)

const SerialPage = loadable(
  () => import('client/bookmate/featured/serial-page/serial-page'),
  {
    resourceFallback,
  },
)
const SerialSectionBox = loadable(
  () => import('client/bookmate/featured/serial-page/serial-section-box'),
  {
    resourceFallback,
  },
)
const SerialIndex = loadable(
  () => import('client/bookmate/featured/serial-page/serial-index'),
  {
    resourceFallback,
  },
)

const SerialEpisodesList = loadable(
  () => import('client/bookmate/featured/serial-page/serial-episodes-list'),
  {
    fallback: NullComponent,
  },
)

const Reader = loadable(
  () => import('client/bookmate/featured/reader-page/reader-index'),
)

const ReviewNewsletterPage = loadable(
  () => import('client/bookmate/pages/review-newsletter-page'),
  {
    fallback: resourceFallback,
  },
)

const ReviewNewsletterSuccessPage = loadable(
  () => import('client/bookmate/pages/review-newsletter-success-page'),
  {
    fallback: resourceFallback,
  },
)

const ReviewNewsletterErrorPage = loadable(
  () => import('client/bookmate/pages/review-newsletter-error-page'),
  {
    fallback: resourceFallback,
  },
)

const ReviewNewsletterConfirmPage = loadable(
  () => import('client/bookmate/pages/review-newsletter-confirm-page'),
  {
    fallback: resourceFallback,
  },
)

const routes = (
  <Route path="/" component={props => <App {...props} />}>
    <Route path="keri2024" component={props => <KeriCinPage {...props} />} />
    <Route path="original">
      <Route
        path="nemirni-grof"
        component={props => <RsOriginal {...props} />}
      />
      <Route
        path="mariposa"
        component={props => <LatamOriginal {...props} campaign={MARIPOSA} />}
      />
      <Route
        path="el-teatro"
        component={props => <LatamOriginal {...props} campaign={EL_TEATRO} />}
      />
      <Route
        path="lascavidades"
        component={props => <LatamOriginal {...props} campaign={VIDADES} />}
      />

      <Route
        path="elcorazondelabestia"
        component={props => <LatamElCorazonDeLsBestia {...props} />}
      />

      <Route path="pas" component={props => <PasOriginal {...props} />} />
      <Route
        path="najnormalniji"
        component={props => <CovekOriginal {...props} />}
      />
      <Route
        path="paolacarola"
        component={props => <PaolacarolaOriginal {...props} />}
      />
      <Route
        path="worldcoup"
        component={props => <WorldCoupOriginal {...props} />}
      />
      <Route
        path="itojeuredu"
        component={props => <FeelingsOriginal {...props} />}
      />
    </Route>
    <Route path="/a1">
      <IndexRoute component={props => <A1Page {...props} />} />
      <Route path="share" component={props => <A1ShareBox {...props} />} />
      <Route path="success" component={props => <A1SuccessBox {...props} />} />
    </Route>
    <Route path="/codigo">
      <IndexRoute component={props => <CodigoPage {...props} />} />
      <Route path="share" component={props => <CodigoShareBox {...props} />} />
      <Route
        path="success"
        component={props => <CodigoSuccessBox {...props} />}
      />
    </Route>
    <Route path="/telcel">
      <IndexRoute component={props => <TelcelPage {...props} />} />
      <Route path="share" component={props => <TelcelShareBox {...props} />} />
      <Route
        path="success"
        component={props => <TelcelSuccessBox {...props} />}
      />
    </Route>
    <Route path="/3mesesx99">
      <IndexRoute
        component={props => <TelcelPage {...props} campaign={THREE_MES} />}
      />
      <Route path="share" component={props => <TelcelShareBox {...props} />} />
      <Route
        path="success"
        component={props => <TelcelSuccessBox {...props} />}
      />
    </Route>

    <Route path="/mtn/entered">
      <IndexRoute component={props => <MTNGhana {...props} />} />
      <Route path="login" component={props => <LoginPage {...props} />} />
      <Route path="tariffs" component={props => <MTNTariffs {...props} />} />
      <Route path="payment" component={props => <MTNPayment {...props} />} />
      <Route path="success" component={props => <MTNSuccess {...props} />} />
      <Route path="404" component={props => <MTN404 {...props} />} />
    </Route>
    <Route path="/mtn-monthly/entered">
      <IndexRoute component={props => <MTNGhana {...props} />} />
      <Route path="login" component={props => <LoginPage {...props} />} />
      <Route path="tariffs" component={props => <MTNTariffs {...props} />} />
      <Route path="payment" component={props => <MTNPayment {...props} />} />
      <Route path="success" component={props => <MTNSuccess {...props} />} />
      <Route path="404" component={props => <MTN404 {...props} />} />
    </Route>
    <Route
      path="/subscription_purchase/:id"
      component={props => <SubscriptionPaymentPendingBox {...props} />}
    />
    <Route
      path="/cheap_trial_purchases/:id"
      component={props => <CheapTrialPendingBox {...props} />}
    />
    <Route
      path="/gift_purchase/:id"
      component={props => <GiftsPaymentPendingBox {...props} />}
    />
    <Route
      path="/promo_purchases/:id"
      component={props => <PromoPaymentPendingBox {...props} />}
    />
    <Route path="/bibliotecasocio">
      <IndexRoute component={props => <SalinasLibrary {...props} />} />
      <Route path="sign-up" component={props => <SalinasSignUp {...props} />} />
      <Route
        path="confirm/:type"
        component={props => <SalinasValidate {...props} />}
      />
    </Route>
    <Route path="/bookmate-run" component={props => <DinoGame {...props} />} />
    <Route
      path="/yettel-hu"
      component={props => <YettelHungary {...props} />}
    />
    <Route
      path="/topics/:query"
      component={props => <TopicAllBox {...props} />}
    />
    <Route path="/library">
      <IndexRoute component={props => <ShowcasePage {...props} />} />
      <Route
        path="s-:section"
        component={props => <ShowcaseSectionBox {...props} />}
      />
      <Route path="t-:topic">
        <IndexRoute component={props => <ShowcaseTopicBox {...props} />} />
        <Route
          path="t-:subtopic(/:page)"
          component={props => <TopicAllBox {...props} />}
          ignoreScrollBehavior
        />
        <Route
          path="all(/:page)"
          component={props => <TopicAllBox {...props} />}
          ignoreScrollBehavior
        />
        <Route
          path="s-:section"
          component={props => <ShowcaseTopicSectionBox {...props} />}
        />
      </Route>
      <Route
        path="@:sectionSlug"
        component={props => <ShowcasePage {...props} />}
      />
    </Route>
    <Route
      path="/sitemap/:resourceType(/:page)"
      component={props => <SitemapPage {...props} />}
    />
    <IndexRoute component={props => <IndexPage {...props} />} />
    <Redirect from="/achievements/:username" to="/@:username/achievements" />
    <Redirect
      from="/achievements/:username/*"
      to="/@:username/achievements/*"
    />
    <Route
      path="/subscription"
      component={props => <SubscriptionPage {...props} />}
    >
      <IndexRoute component={props => <SubscriptionIndexBox {...props} />} />
      <Route
        path="featured-product"
        component={props => <SubscriptionFeaturedProductBox {...props} />}
      />
      <Route
        path="complete"
        component={props => <SubscriptionCompleteBox {...props} />}
      />
      <Route
        path="complete/paypal"
        component={props => <SubscriptionCompletePaypalBox {...props} />}
      />
      <Route
        path="success"
        component={props => <SubscriptionSuccessBox {...props} />}
      />
      <Route path=":productId">
        <IndexRoute component={props => <SubscriptionFormBox {...props} />} />
        <Route
          path="upgrade"
          component={props => <SubscriptionUpgradeBox {...props} />}
        />
      </Route>
    </Route>
    {/* the manage-subscriptions route might become a child of /settings */}
    <Route
      path="/manage-subscriptions"
      component={props => <ManageSubscriptionsPage {...props} />}
    >
      <IndexRoute component={props => <ManageSubscriptionsBox {...props} />} />
    </Route>
    <Route
      path="/search/tag/:query"
      status={404}
      component={props => <StatusErrorPage {...props} />}
    />
    <Route
      path="/search/author/:query"
      status={404}
      component={props => <StatusErrorPage {...props} />}
    />
    <Route
      path="/search(/:section)(/:query)"
      component={props => <SearchPage {...props} />}
    />
    <Redirect from="/books/search" to="/search" />
    <Redirect from="/authors" to="/search" />
    {/* the /authors/:authorName/books route below is obsolete; the component it matches will fetch some additional info and redirect */}
    <Route
      path="/authors/:authorName/books"
      component={props => <AuthorBooksBox {...props} />}
    />
    <Route
      path="/authors/:authorId"
      component={props => <AuthorPage {...props} />}
    >
      <IndexRoute component={props => <AuthorIndexBox {...props} />} />
      <Route
        path=":role/impressions"
        component={props => <AuthorImpressionsBox {...props} />}
      />
      <Route
        path=":role/quotes"
        component={props => <AuthorQuotesBox {...props} />}
      />
      <Route
        path=":role/media-links"
        component={props => <AuthorMediaLinksBox {...props} />}
      />
      <Route
        path=":role/series"
        component={props => <AuthorSeriesBox {...props} />}
      />
      <Route
        path=":role/:worksType"
        component={props => <AuthorWorksBox {...props} />}
      />
      <Redirect
        from="/books/impressions/:impressionId"
        to="/impressions/:impressionId"
      />
    </Route>
    <Route
      path="/books/:uuid/impressions/form"
      component={props => <ItemImpressionFormPage {...props} />}
      resourceType="book"
    />
    {/* the following route needs to be above the `/books/:uuid` routes to ensure matching `/books/:bookId/add_to_shelf` */}
    <Route
      path="/:resourceType/:resourceId/add_to_shelf"
      component={props => <ShelfSelectionBox {...props} />}
    />
    <Route path="/books">
      <IndexRoute component={props => <ShowcasePage {...props} />} />
      <Route
        path="s-:section"
        component={props => <ShowcaseSectionBox {...props} />}
      />
      <Route path="t-:topic">
        <IndexRoute component={props => <ShowcaseTopicBox {...props} />} />
        <Route
          path="t-:subtopic(/:page)"
          component={props => <TopicAllBox {...props} />}
          ignoreScrollBehavior
        />
        <Route
          path="all(/:page)"
          component={props => <TopicAllBox {...props} />}
          ignoreScrollBehavior
        />
        <Route
          path="s-:section"
          component={props => <ShowcaseTopicSectionBox {...props} />}
        />
      </Route>
      <Route
        path="@:sectionSlug"
        component={props => <ShowcasePage {...props} />}
      />
    </Route>
    <Route path="/books/:uuid" component={props => <BookPage {...props} />}>
      <Route
        component={props => <BookSectionBox {...props} resourceType="book" />}
      >
        <IndexRoute component={props => <BookIndexBox {...props} />} />
        <Route
          path="quotes"
          component={props => <BookQuotesBox {...props} />}
          resourceType="book"
        />
        <Route
          path="impressions"
          component={props => <ItemImpressionsBox {...props} />}
          resourceType="book"
        />
        <Route
          path="readers"
          component={props => <BookReadersBox {...props} />}
          resourceType="book"
        />
        <Route
          path="related"
          component={props => <BookRelatedBox {...props} />}
          resourceType="book"
        />
        <Route
          path="shelves"
          component={props => <BookShelvesBox {...props} />}
          resourceType="book"
        />
        <Route
          path="other-episodes"
          component={props => <ItemOtherEpisodesBox {...props} />}
          resourceType="book"
        />
        <Route
          path="publisher"
          component={props => <ResourcePublisherBox {...props} />}
          resourceType="book"
        />
      </Route>
      <Route
        path="completed"
        component={props => <BookCompletedBox {...props} />}
        resourceType="book"
      />
      <Route path="*" component={props => <BookIndexBox {...props} />} />
    </Route>
    <Route path="/comicbooks">
      <IndexRoute component={props => <ShowcasePage {...props} />} />
      <Route
        path="s-:section"
        component={props => <ShowcaseSectionBox {...props} />}
      />
      <Route path="t-:topic">
        <IndexRoute component={props => <ShowcaseTopicBox {...props} />} />
        <Route
          path="t-:subtopic(/:page)"
          component={props => <TopicAllBox {...props} />}
          ignoreScrollBehavior
        />
        <Route
          path="all(/:page)"
          component={props => <TopicAllBox {...props} />}
          ignoreScrollBehavior
        />
        <Route
          path="s-:section"
          component={props => <ShowcaseTopicSectionBox {...props} />}
        />
      </Route>
      <Route
        path="@:sectionSlug"
        component={props => <ShowcasePage {...props} />}
      />
    </Route>
    <Route
      path="/comicbooks/:uuid"
      component={props => <ComicbookPage {...props} />}
    >
      <Route
        path="/comicbooks/:uuid/impressions/form"
        component={props => <ItemImpressionFormPage {...props} />}
        resourceType="comicbook"
      />
      <Route component={props => <ComicbookSectionBox {...props} />}>
        <IndexRoute component={props => <ComicbookIndexBox {...props} />} />
        <Route
          path="related"
          component={props => <ComicbookRelatedBox {...props} />}
        />
        <Route
          path="shelves"
          component={props => <ComicbookShelvesBox {...props} />}
        />
        <Route
          path="impressions"
          component={props => <ItemImpressionsBox {...props} />}
          resourceType="comicbook"
        />
        <Route
          path="other-episodes"
          component={props => <ItemOtherEpisodesBox {...props} />}
          resourceType="comicbook"
        />
        <Route
          path="readers"
          component={props => <ComicbookReadersBox {...props} />}
        />
        <Route
          path="publisher"
          component={props => <ResourcePublisherBox {...props} />}
          resourceType="comicbook"
        />
      </Route>
      <Route path="*" component={props => <ComicbookIndexBox {...props} />} />
    </Route>
    <Route path="/audiobooks">
      <IndexRoute component={props => <ShowcasePage {...props} />} />
      <Route
        path="s-:section"
        component={props => <ShowcaseSectionBox {...props} />}
      />
      <Route path="t-:topic">
        <IndexRoute component={props => <ShowcaseTopicBox {...props} />} />
        <Route
          path="t-:subtopic(/:page)"
          component={props => <TopicAllBox {...props} />}
          ignoreScrollBehavior
        />
        <Route
          path="all(/:page)"
          component={props => <TopicAllBox {...props} />}
          ignoreScrollBehavior
        />
        <Route
          path="s-:section"
          component={props => <ShowcaseTopicSectionBox {...props} />}
        />
      </Route>
      <Route
        path="@:sectionSlug"
        component={props => <ShowcasePage {...props} />}
      />
    </Route>
    <Route
      path="/audiobooks/:uuid"
      component={props => <AudioBookPage {...props} />}
    >
      <Route
        path="/audiobooks/:uuid/impressions/form"
        component={props => <ItemImpressionFormPage {...props} />}
        resourceType="audiobook"
      />
      <Route component={props => <AudioBookSectionBox {...props} />}>
        <IndexRoute component={props => <AudiobookIndexBox {...props} />} />
        <Route
          path="listeners"
          component={props => <AudioBookListenersBox {...props} />}
        />
        <Route
          path="related"
          component={props => <AudioBookRelatedBox {...props} />}
        />
        <Route
          path="impressions"
          component={props => <ItemImpressionsBox {...props} />}
          resourceType="audiobook"
        />
        <Route
          path="other-episodes"
          component={props => <ItemOtherEpisodesBox {...props} />}
          resourceType="audiobook"
        />
        <Route
          path="publisher"
          component={props => <ResourcePublisherBox {...props} />}
          resourceType="audiobook"
        />
      </Route>
      <Route path="*" component={props => <AudiobookIndexBox {...props} />} />
    </Route>
    <Route
      path="/serials/:uuid/impressions/form"
      component={props => <ItemImpressionFormPage {...props} />}
      resourceType="book"
    />
    <Route path="/serials/:uuid" component={props => <SerialPage {...props} />}>
      <Route
        component={props => (
          <SerialSectionBox {...props} resourceType="serial" />
        )}
      >
        <IndexRoute component={props => <SerialIndex {...props} />} />
        <Route
          path="/serials/:uuid/episodes"
          component={props => <SerialEpisodesList {...props} />}
        />
        <Route
          path="quotes"
          component={props => <BookQuotesBox {...props} />}
          resourceType="serial"
        />
        <Route
          path="impressions"
          component={props => <ItemImpressionsBox {...props} />}
          resourceType="serial"
        />
        <Route
          path="readers"
          component={props => <BookReadersBox {...props} />}
          resourceType="serial"
        />
        <Route
          path="publisher"
          component={props => <ResourcePublisherBox {...props} />}
          resourceType="book"
        />
        <Route
          path="related"
          component={props => <BookRelatedBox {...props} />}
          resourceType="serial"
        />
        <Route
          path="shelves"
          component={props => <BookShelvesBox {...props} />}
          resourceType="serial"
        />
      </Route>
      <Route
        path="completed"
        component={props => <BookCompletedBox {...props} />}
        resourceType="serial"
      />
    </Route>
    <Route path="/series/:uuid" component={props => <SeriesPage {...props} />}>
      <IndexRoute component={props => <SeriesIndexBox {...props} />} />
      <Route component={props => <SeriesSectionBox {...props} />}>
        <Route
          path="followers"
          component={props => <SeriesFollowersBox {...props} />}
        />
        <Route
          path="episodes"
          component={props => <SeriesEpisodesBox {...props} />}
        />
      </Route>
    </Route>
    <Route
      path="/impressions/:impressionId"
      component={props => <ImpressionPage {...props} />}
    >
      <IndexRoute component={props => <ImpressionIndexBox {...props} />} />
    </Route>
    <Route
      path="/quotes/:quoteId"
      component={props => <QuotePage {...props} />}
    >
      <IndexRoute component={props => <QuoteIndexBox {...props} />} />
    </Route>
    <Route
      path="/bookshelves/all(/:page)"
      component={props => <ShelvesPage {...props} />}
      ignoreScrollBehavior
    >
      <IndexRoute component={props => <ShelvesLayoutBox {...props} />} />
      <Route
        path="/bookshelves/t-:topic(/:page)"
        component={props => <ShelvesLayoutBox {...props} />}
        ignoreScrollBehavior
      />
      <Route
        path="/bookshelves/following(/:page)"
        component={props => <ShelvesLayoutBox {...props} />}
        ignoreScrollBehavior
      />
      <Route
        path="/bookshelves/friends(/:page)"
        component={props => <ShelvesLayoutBox {...props} />}
        ignoreScrollBehavior
      />
    </Route>
    <Route
      path="/bookshelves/new"
      component={props => <ShelfFormBox {...props} />}
    />
    <Route
      path="/bookshelves/:uuid"
      component={props => <ShelfPage {...props} />}
    >
      <IndexRoute component={props => <ShelfIndexBox {...props} />} />
      <Route
        path="/bookshelves/:uuid/add(/:resourceType)"
        component={props => <ShelfResourceSearchBox {...props} />}
      />
      <Route
        path="/bookshelves/:uuid/add/:resourceType/:resourceId"
        component={props => <PostFormBox {...props} />}
      />
      <Route
        path="/bookshelves/:uuid/posts/:postUuid/edit"
        component={props => <PostFormBox {...props} />}
      />
      <Route
        path="/bookshelves/:uuid/edit"
        component={props => <ShelfFormBox {...props} />}
      />
      <Route component={props => <ShelfSectionBox {...props} />}>
        <Route path="books" component={props => <ShelfBooksBox {...props} />} />
        <Route
          path="posts/:postUuid"
          component={props => <ShelfPostsBox {...props} />}
        />
        <Route path="posts" component={props => <ShelfPostsBox {...props} />} />
        <Route
          path="followers"
          component={props => <ShelfFollowersBox {...props} />}
        />
      </Route>
    </Route>
    <Route path="gettheapp" component={props => <GetTheAppPage {...props} />} />

    <Route path="login" component={props => <LoginPage {...props} />}>
      <Route
        path=":loginmode/:authcode"
        component={props => <LoginPage {...props} />}
      />
    </Route>
    <Route path="@:username" component={props => <UserPage {...props} />}>
      <IndexRoute component={props => <UserIndexBox {...props} />} />
      <Route
        path="books/:filter"
        component={props => <UserBooksBox {...props} />}
        content="books"
      />
      <Route
        path="books/:bookUuid/quotes"
        component={props => <UserBookQuotesBox {...props} />}
      />
      <Route
        path="audiobooks/:filter"
        component={props => <UserBooksBox {...props} />}
        content="audiobooks"
      />
      <Route
        path="comics/:filter"
        component={props => <UserBooksBox {...props} />}
        content="comicbooks"
      />
      <Route
        path="bookshelves/:filter"
        component={props => <UserShelvesBox {...props} />}
      />
      <Route
        path="connections/:filter"
        component={props => <UserConnectionsBox {...props} />}
      />
      <Route
        path="impressions"
        component={props => <UserImpressionsBox {...props} />}
      />
      <Route path="series" component={props => <UserSeriesBox {...props} />} />
      <Route path="quotes" component={props => <UserQuotesBox {...props} />} />
      <Route
        path="achievements(/:year)"
        component={props => <UserAchievementsBox {...props} />}
      />
      <Route
        path="achievements/:year/finished-books"
        component={props => <UserFinishedBooksBox {...props} />}
      />
      <Route
        path="achievements/:year/following"
        component={props => <UserFriendsAchievementsBox {...props} />}
      />
      <Route
        path="achievements/:year/pledge-card"
        component={props => <UserPledgeCardBox {...props} />}
      />
    </Route>
    <Route component={props => <UserPrivateBox {...props} />}>
      <Route
        path="@:username/profile"
        component={props => <UserProfileBox {...props} />}
      />
      <Route
        path="@:username/change-card(/:recurrentUuid)"
        component={props => <UserChangeCardBox {...props} />}
      />
      <Route
        path="@:username/settings"
        component={props => <UserSettingsBox {...props} />}
        settingsType="default"
      />
      <Route
        path="@:username/settings/privacy"
        component={props => <UserSettingsBox {...props} />}
        settingsType="privacy"
      />
      <Route
        path="@:username/settings/cookies"
        component={props => <UserSettingsBox {...props} />}
        settingsType="cookies"
      />
      <Route
        path="@:username/settings/devices-only"
        component={props => <DevicesShortBox {...props} />}
        settingsType="devices"
      />
      <Route
        path="@:username/settings/devices"
        component={props => <UserSettingsBox {...props} />}
        settingsType="devices"
      />
      <Route
        path="@:username/settings/devices/:deviceId/revoke-access"
        component={props => <RevokeDeviceAccessTokenPage {...props} />}
      />
      <Route
        path="@:username/settings/email-notifications"
        component={props => <UserSettingsBox {...props} />}
        settingsType="email-notifications"
      />
      <Route
        path="@:username/settings/change-password"
        component={props => <UserChangePasswordBox {...props} />}
      />
      <Route
        path="@:username/settings/change-email"
        component={props => <UserChangeEmailBox {...props} />}
      />
      <Route
        path="@:username/settings/ru/add-email"
        component={props => <UserAddEmailBox {...props} />}
      />
      <Route
        path="@:username/settings/ru/add-email/success"
        component={props => <UserAddEmailSuccessBox {...props} />}
      />
      <Route
        path="@:username/settings/delete-account/password"
        component={props => <UserDeleteAccountPasswordBox {...props} />}
      />
      <Route
        path="@:username/settings/family-subscription"
        component={props => <UserFamilySubscriptionBox {...props} />}
      />
      <Route
        path="@:username/settings/family-subscription/invite-sent"
        component={props => <FamilySubscriptionInviteSentPage {...props} />}
      />
    </Route>
    <Route
      path="/reset-password"
      component={props => <ResetPasswordBox {...props} />}
    />
    <Route
      path="/upload"
      component={props => <UploadPage {...props} />}
      mode="drag-n-drop"
    />
    <Route
      path="/upload/from-external-link"
      component={props => <UploadPage {...props} />}
      mode="link"
    />
    <Route
      path="/notifications"
      component={props => <NotificationsPage {...props} />}
    />
    <Route path="/feed" component={props => <FeedPage {...props} />}>
      <IndexRoute onEnter={(nextState, replace) => replace('feed/explore')} />
      <Route
        path="friends"
        component={props => <FriendsFeedBox {...props} />}
      />
      <Route
        path="authors"
        component={props => <AuthorsFeedBox {...props} />}
      />
      <Route
        path="explore"
        component={props => <CommunityFeedBox {...props} />}
      />
    </Route>
    <Route
      path="/report-problem"
      component={props => <ReportProblemPage {...props} />}
    />
    <Route
      path="/restricted-by-region"
      component={props => <UnavailablePage {...props} />}
    />
    <Redirect from="/specials/starwars" to="/bookshelves/gl4bL1YW" />
    <Redirect from="/specials/reader-podcast" to="/bookshelves/FqRjDRmN" />
    <Redirect from="/specials/authors/:author" to="/library" />

    <Route
      path="/special-offers/:campaign"
      component={props => <SpecialOffersPage {...props} />}
    >
      <Route
        path="payment"
        component={props => <SpecialOffersPaymentBox {...props} />}
      />
      <Route
        path="success"
        component={props => <SpecialOffersSuccess {...props} />}
      />
    </Route>

    <Route
      path="/specials/good_news/:good_news_name"
      component={props => <GoodNewsPage {...props} />}
    />
    <Route
      path="/family"
      component={props => <FamilySubscriptionLandingPage {...props} />}
    >
      <IndexRoute
        component={props => <FamilySubscriptionLandingIndex {...props} />}
      />
      <Route
        path="invite/:uuid"
        component={props => <FamilySubscriptionInviteBox {...props} />}
      ></Route>
    </Route>

    <Route path="/gifts" component={props => <GiftsPage {...props} />}>
      <IndexRoute component={props => <GiftsIndexBox {...props} />} />
      <Route
        path="customise"
        component={props => <GiftCustomiseBox {...props} />}
      />
      <Route
        path="payment"
        component={props => <GiftPaymentBox {...props} />}
      />
      <Route
        path="payment/success"
        component={props => <GiftsPaymentSuccessBox {...props} />}
      />
      <Route
        path="payment/fail"
        component={props => <GiftsPaymentFailBox {...props} />}
      />
      <Route
        path="suggest-book(/:resourceType)"
        component={props => <BookSuggestionBox {...props} />}
      />
    </Route>

    <Route
      path="/gifts/success"
      component={props => <GiftsSuccessBox {...props} />}
    />

    <Route
      path="/gifts/:giftId"
      component={props => <GiftPresentingPage {...props} />}
    >
      <IndexRoute component={props => <GiftPresentingBox {...props} />} />
    </Route>

    <Redirect from="/rabatkode" to="/code" />
    <Redirect from="/kode" to="/code" />
    <Route path="/code">
      <IndexRoute component={props => <CodePage {...props} />} />
      <Route
        path="subscription"
        component={props => <CodeSubscriptionBox {...props} />}
      />
      <Route path="share" component={props => <CodeShareBox {...props} />} />
      <Route
        path="success"
        component={props => <CodeSuccessBox {...props} />}
      />
    </Route>
    <Route path="/mol">
      <IndexRoute component={props => <CodePage {...props} />} />
      <Route
        path="subscription"
        component={props => <CodeSubscriptionBox {...props} />}
      />
      <Route path="share" component={props => <CodeShareBox {...props} />} />
      <Route
        path="success"
        component={props => <CodeSuccessBox {...props} />}
      />
    </Route>
    <Route path="/about">
      <IndexRoute component={props => <AboutPage {...props} />} />
      <Route path="contacts" component={props => <ContactsPage {...props} />} />
      <Route
        path="legal(/:subItem)"
        component={props => <LegalPage {...props} />}
      />
      <Route path="press" component={props => <PressPage {...props} />} />
      <Route path="jobs" component={props => <JobsPage {...props} />} />
      <Route path="jobs/:jobId" component={props => <JobPage {...props} />} />
    </Route>
    <Route
      path="/mailing/unsubscribe"
      component={props => <UnsubscribePage {...props} />}
    />
    <Route path="/reader/:bookId" component={props => <Reader {...props} />} />
    <Redirect
      from="/:username([a-z][a-z0-9]{2,22})/quotes/:uuid([a-z][a-z0-9]{7})"
      to="/quotes/:uuid"
    />
    <Route path="newsletter">
      <IndexRoute component={props => <ReviewNewsletterPage {...props} />} />
      <Route
        path="success"
        component={props => <ReviewNewsletterSuccessPage {...props} />}
      />
      <Route
        path="error"
        component={props => <ReviewNewsletterErrorPage {...props} />}
      />
      <Route
        path="confirm"
        component={props => <ReviewNewsletterConfirmPage {...props} />}
      />
    </Route>
    <Route path="/decije-knjige">
      <IndexRoute component={props => <KidsShowcase {...props} />} />
      <Route
        path="s-:section"
        component={props => <KidsSectionBox {...props} />}
      />
      <Route
        path="@:sectionSlug"
        component={props => <ShowcasePage {...props} />}
      />
    </Route>
  </Route>
)

export default routes
